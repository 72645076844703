var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", [
    _c(
      "div",
      {
        staticClass: "box box--filled box--sm box--minHeight",
        class: { "box--flexCenter": _vm.itemToUpload.progress < 100 },
      },
      [
        _vm.itemToUpload.progress < 100
          ? _c("UploadProgressBar", {
              attrs: { itemToUpload: _vm.itemToUpload },
            })
          : _vm.itemToUpload.progress >= 100
          ? [
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      return _vm.DELETE_STAGED_FILE(_vm.itemToUpload.id)
                    },
                  },
                },
                [
                  _c("icon", {
                    staticClass: "box__cornerIcon",
                    attrs: { name: "trash" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "formGroup" }, [
                _c("p", { staticClass: "box__file-extension" }, [
                  _vm._v(_vm._s(_vm.fileExtension)),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "box__beat-name",
                    attrs: { title: _vm.itemToUpload.file.name },
                  },
                  [_vm._v(_vm._s(_vm.itemToUpload.file.name))]
                ),
              ]),
              _c("div", { staticClass: "formGroup" }, [
                _c(
                  "div",
                  {
                    staticClass: "formUpload formUpload--small",
                    class: {
                      "formUpload--empty": !_vm.beat.image_url,
                      "formUpload--uploaded": _vm.beat.image_url,
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "formUpload__icon",
                      attrs: { name: "camera" },
                    }),
                    _vm.beat.image_url
                      ? _c("img", {
                          staticClass: "formUpload__image",
                          attrs: { src: _vm.beat.image_url },
                        })
                      : _vm._e(),
                    _c("input", {
                      staticClass: "formUpload__inputHide",
                      attrs: { type: "file" },
                      on: {
                        change: function ($event) {
                          return _vm.addImageToBeat($event.target.files)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "formGroup formGroup--horizontal" }, [
                _c(
                  "div",
                  { staticClass: "flex75" },
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "Name", rules: { required: true } },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return [
                              _c("label", { staticClass: "formLabel" }, [
                                _vm._v("Name"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.beat.name,
                                    expression: "beat.name",
                                  },
                                ],
                                staticClass: "formInput formInput--fullWidth",
                                class: { hasError: errors.length > 0 },
                                attrs: {
                                  name: "name",
                                  type: "text",
                                  placeholder: "Enter beat name",
                                },
                                domProps: { value: _vm.beat.name },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.beat,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _c(
                                "p",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: errors[0],
                                      expression: "errors[0]",
                                    },
                                  ],
                                  staticClass:
                                    "formInput__hint formInput__hint--error",
                                },
                                [_vm._v("Name is required")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex25" },
                  [
                    _c("ValidationProvider", {
                      attrs: { name: "bpm", rules: { numeric: true } },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var errors = ref.errors
                            return [
                              _c("label", { staticClass: "formLabel" }, [
                                _vm._v("BPM"),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.beat.bpm,
                                    expression: "beat.bpm",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass:
                                  "formInput formInput--fullWidth formInput--bpm",
                                class: { hasError: errors.length > 0 },
                                attrs: {
                                  name: "bpm",
                                  type: "text",
                                  placeholder: "90",
                                },
                                domProps: { value: _vm.beat.bpm },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.beat,
                                      "bpm",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "formInput__hint formInput__hint--error",
                                },
                                [_vm._v(_vm._s(errors[0]))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
              _c("BeatInfoDropdown", {
                attrs: {
                  selectedArtistType: _vm.beat.artist_type
                    ? _vm.beat.artist_type
                    : null,
                  selectedGenre: _vm.beat.genre ? _vm.beat.genre : null,
                  selectedMainInstrument: _vm.beat.main_instrument
                    ? _vm.beat.main_instrument
                    : null,
                  errorOnArtistType: _vm.errorOnArtistType,
                  errorOnGenre: _vm.errorOnGenre,
                  errorOnMainInstrument: _vm.errorOnMainInstrument,
                },
                on: {
                  setArtistType: _vm.setArtistType,
                  setGenre: _vm.setGenre,
                  setMainInstrument: _vm.setMainInstrument,
                },
              }),
              _c(
                "div",
                { staticClass: "formGroup" },
                [
                  _c("ValidationProvider", {
                    attrs: { name: "URL", rules: { regex: _vm.regexUrl } },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("label", { staticClass: "formLabel" }, [
                              _vm._v("Trackout Link"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.beat.trackout_external_url,
                                  expression: "beat.trackout_external_url",
                                },
                              ],
                              staticClass: "formInput formInput--fullWidth",
                              class: { hasError: errors.length > 0 },
                              attrs: {
                                name: "url",
                                type: "text",
                                placeholder: "https://www....",
                              },
                              domProps: {
                                value: _vm.beat.trackout_external_url,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.beat,
                                    "trackout_external_url",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "formInput__hint formInput__hint--error",
                              },
                              [_vm._v(_vm._s(errors[0]))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "formGroup" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.beat.sampled,
                      expression: "beat.sampled",
                    },
                  ],
                  staticClass: "formCheckbox",
                  class: { checked: _vm.beat.sampled },
                  attrs: {
                    id: "sampled-" + _vm.itemToUpload.id,
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.beat.sampled)
                      ? _vm._i(_vm.beat.sampled, null) > -1
                      : _vm.beat.sampled,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.beat.sampled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.beat, "sampled", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.beat,
                              "sampled",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.beat, "sampled", $$c)
                      }
                    },
                  },
                }),
                _c(
                  "label",
                  { attrs: { for: "sampled-" + _vm.itemToUpload.id } },
                  [_vm._v("Contains Samples")]
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }