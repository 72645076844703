var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "80%" } }, [
    _c("p", [_vm._v(_vm._s(_vm.itemToUpload.file.name))]),
    _c("div", { staticClass: "upload-progress-bar" }, [
      _c("div", {
        staticClass: "upload-progress-bar__completion",
        style: "width: " + _vm.itemToUpload.progress + "%",
      }),
    ]),
    _c(
      "button",
      {
        staticClass: "btn btn--sm btn--darkGrey btn--border",
        on: { click: _vm.cancelUpload },
      },
      [_vm._v("Cancel")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }