var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-producer" },
    [
      _vm._m(0),
      _vm.uploadedBeatsData.reaching_beats_upload_limit
        ? _c("AlertBox", {
            attrs: {
              title: "Reaching upload limit",
              description:
                "You are reaching the maximum number of uploads you can make with free account",
              severity: "warning",
              link: { to: "Subscription", text: "Upgrade to premium" },
            },
          })
        : _vm._e(),
      _vm.uploadedBeatsData.can_upload_beats === false
        ? _c("AlertBox", {
            attrs: {
              title: "Reached upload limit",
              description:
                "You have reached the maximum number of uploads you can make with free account",
              severity: "error",
              link: { to: "Subscription", text: "Upgrade to premium" },
            },
          })
        : _vm.step === _vm.UploadStep.Initial
        ? [
            _c("section", { staticClass: "section" }, [
              _vm.queryType === _vm.UploadType.NewBeat
                ? _c(
                    "div",
                    {
                      staticClass:
                        "container container--fullWidth wrapper--filled wrapper--paddingLg",
                    },
                    [
                      _c("div", { staticClass: "drop-zone__description" }, [
                        _c("h4", [_vm._v("Audio file conversion")]),
                        _c("p", { staticClass: "textSizeSm p--lightGrey" }, [
                          _vm._v(
                            "WAVs are automatically converted to MP3, as well as the tagged MP3. *Must have file with tags uploaded"
                          ),
                        ]),
                        _c("p", { staticClass: "textSizeSm p--lightGrey" }, [
                          _vm._v(
                            "MP3s are converted to tagged MP3. *Must have file with tags uploaded"
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "grid grid__col2" },
                        [
                          _c("DropZone", {
                            attrs: { "zone-type": _vm.UploadType.NewBeat },
                          }),
                          _c("DropZone", {
                            attrs: { "zone-type": _vm.UploadType.MatchBeat },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.queryType === _vm.UploadType.TaggedBeat
                ? _c(
                    "div",
                    {
                      staticClass:
                        "container container--fullWidth wrapper--filled wrapper--paddingLg",
                    },
                    [
                      _c("div", { staticClass: "drop-zone__description" }, [
                        _c("h4", [_vm._v("Tagged Beats")]),
                        _c("p", { staticClass: "textSizeSm p--lightGrey" }, [
                          _vm._v("Upload your tagged beats"),
                        ]),
                      ]),
                      _c("DropZone", {
                        attrs: { "zone-type": _vm.UploadType.TaggedBeat },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.queryType === _vm.UploadType.Public
                ? _c(
                    "div",
                    {
                      staticClass:
                        "container container--fullWidth wrapper--filled wrapper--paddingLg",
                    },
                    [
                      _c("div", { staticClass: "drop-zone__description" }, [
                        _c("h4", [_vm._v("Artworks")]),
                        _c("p", { staticClass: "textSizeSm p--lightGrey" }, [
                          _vm._v("Add images to your beats"),
                        ]),
                      ]),
                      _c("DropZone", {
                        attrs: { "zone-type": _vm.UploadType.Public },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        : [
            _c("section", { staticClass: "section" }, [
              _vm.type === _vm.UploadType.NewBeat
                ? _c(
                    "div",
                    {
                      staticClass:
                        "container container--fullWidth grid grid__col4",
                    },
                    _vm._l(_vm.stagedFiles, function (itemToUpload) {
                      return _c("upload-box-beat-new", {
                        key: itemToUpload.id,
                        attrs: { itemToUpload: itemToUpload },
                      })
                    }),
                    1
                  )
                : _vm.type === _vm.UploadType.MatchBeat ||
                  _vm.type === _vm.UploadType.Public ||
                  _vm.type === _vm.UploadType.TaggedBeat
                ? _c(
                    "div",
                    {
                      staticClass:
                        "container container--fullWidth grid grid__col4",
                    },
                    _vm._l(_vm.stagedFiles, function (itemToUpload) {
                      return _c("upload-box-beat-match", {
                        key: itemToUpload.id,
                        attrs: {
                          itemToUpload: itemToUpload,
                          "is-image": _vm.type === _vm.UploadType.Public,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
            ]),
            _c("section", { staticClass: "section__bottom-button" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn--primary",
                  on: {
                    click: function ($event) {
                      return _vm.ALLOW_SERVER_UPLOAD()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.type === _vm.UploadType.NewBeat
                        ? "UPLOAD BEATS"
                        : "SAVE NEW UPLOADS"
                    )
                  ),
                ]
              ),
            ]),
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "container container--fullWidth" },
                [_c("DropZone", { attrs: { "zone-type": _vm.type } })],
                1
              ),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "container headerContainer" }, [
        _c("div", { staticClass: "headerContainer__heading" }, [
          _c("h3", [_vm._v("Beats")]),
          _c("h1", [_vm._v("Upload")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }