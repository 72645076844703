var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "form formUpload box box--lg box--borderDashed h-textCenter",
      attrs: { enctype: "multipart/form-data", novalidate: "" },
    },
    [
      _c("icon", {
        staticClass: "box__mainIcon",
        attrs: { name: _vm.layoutSettings.icon },
      }),
      _c("h2", [_vm._v(_vm._s(_vm.layoutSettings.heading))]),
      _c("p", { staticClass: "textSizeSm p--lightGrey" }, [
        _vm._v(_vm._s(_vm.layoutSettings.paragraph) + " "),
      ]),
      _c("input", {
        staticClass: "formUpload__inputHide",
        attrs: { type: "file", multiple: "", accept: _vm.acceptFile },
        on: {
          change: function ($event) {
            return _vm.multipleFilesUpload($event.target.files)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }