var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box box--filled box--sm",
      class: {
        "box--minHeight": _vm.isImage,
        "box--minHeight box--flexCenter": _vm.itemToUpload.progress < 100,
      },
    },
    [
      _vm.itemToUpload.progress < 100
        ? _c("upload-progress-bar", {
            attrs: { itemToUpload: _vm.itemToUpload },
          })
        : _vm.itemToUpload.progress >= 100
        ? [
            !_vm.isImage
              ? [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.DELETE_STAGED_FILE(_vm.itemToUpload.id)
                        },
                      },
                    },
                    [
                      _c("icon", {
                        staticClass: "box__cornerIcon",
                        attrs: { name: "trash" },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "h-mb-10" }, [
                    _c("p", { staticClass: "box__file-extension" }, [
                      _vm._v(_vm._s(_vm.fileExtension)),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "box__beat-name",
                        attrs: { title: _vm.itemToUpload.file.name },
                      },
                      [_vm._v(_vm._s(_vm.itemToUpload.file.name))]
                    ),
                  ]),
                ]
              : [
                  _c(
                    "div",
                    {
                      staticClass: "formGroup formUpload formUpload--uploaded",
                    },
                    [
                      _c("img", {
                        staticClass: "formUpload__image",
                        attrs: { src: _vm.image },
                      }),
                    ]
                  ),
                ],
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.closeMenuDropdown,
                    expression: "closeMenuDropdown",
                  },
                ],
                staticClass: "formGroup dropdown",
              },
              [
                _c("label", { staticClass: "formLabel" }, [
                  _vm._v("Match file to beat"),
                ]),
                _c(
                  "a",
                  {
                    staticClass:
                      "formInput formInput--fullWidth formInput--selectCustom",
                    on: { click: _vm.toggleMenuDropdown },
                  },
                  [
                    _vm._v(_vm._s(_vm.beatToPatch.name || "Select Beat")),
                    _c("icon", { attrs: { name: "arrow-down" } }),
                  ],
                  1
                ),
                _vm.menuDropDown
                  ? _c(
                      "ul",
                      {
                        staticClass: "dropdown__menu dropdown__menu--withInput",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.searchInput,
                              expression: "searchInput",
                            },
                            {
                              name: "focus",
                              rawName: "v-focus",
                              value: true,
                              expression: "true",
                            },
                          ],
                          staticClass: "formInput formInput--fullWidth",
                          attrs: { type: "text", placeholder: "Filter Beats" },
                          domProps: { value: _vm.searchInput },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.searchInput = $event.target.value
                            },
                          },
                        }),
                        _vm._l(_vm.filteredBeats, function (beat) {
                          return _c(
                            "li",
                            {
                              key: beat.id,
                              staticClass: "dropdown__item dropdown__item--sm",
                              class: {
                                isActive: beat.name === _vm.beatToPatch.name,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.setBeat(beat)
                                },
                              },
                            },
                            [_vm._v(_vm._s(beat.name))]
                          )
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            ),
            _vm.isImage
              ? _c("div", { staticClass: "form__submit" }, [
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn--sm btn--fullWidth btn--danger btn--border",
                      on: {
                        click: function ($event) {
                          return _vm.DELETE_STAGED_FILE(_vm.itemToUpload.id)
                        },
                      },
                    },
                    [_vm._v("Delete upload")]
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }